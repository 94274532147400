import { differenceInCalendarDays, format } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
const locales = { en: enUS, ru };
// TODO: validate
const getLocale = (localeCode) => locales[localeCode];
const formatDate = (date, localeCode, formatString = 'PP') => format(date, formatString, { locale: getLocale(localeCode) });
const formatProfileJoinedDate = (date, localeCode) => formatDate(date, localeCode, 'dd MMMM yyyy');
const formatPortfolioPeriodDate = (date, localeCode) => formatDate(date, localeCode, 'MMM d, yyyy');
const getDiffInDays = (requiredDate) => Math.abs(differenceInCalendarDays(requiredDate, new Date(Date.now())));
const getCurrentDate = () => new Date(Date.now());
const getCurrentMonthText = (localeCode) => formatDate(new Date(Date.now()), localeCode, 'MMMM');
export { formatDate, formatProfileJoinedDate, formatPortfolioPeriodDate, getLocale, getDiffInDays, getCurrentDate, getCurrentMonthText, };
