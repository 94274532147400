import { IsDefined } from '@utils/js-ts';
const numberToFixed2 = (n) => (Math.round((n + Number.EPSILON) * 100) / 100).toFixed(2);
// -123,123.12 - default
// 123,123.12 - disable sign
// +123,123.12 - enable plus sign
// 123,123 - mfd: 0
const formatNumber = (n, options = {
    // defaults to 2, if 0 provided no fraction digits will be displayed
    maximumFractionDigits: 2,
}) => {
    const maximumFractionDigits = IsDefined(options.maximumFractionDigits) ?
        options.maximumFractionDigits :
        2;
    const plusSignSymbol = options.enablePlusSign ? '+' : '';
    const minusSignSymbol = '-';
    const signSymbol = n >= 0 ? plusSignSymbol : minusSignSymbol;
    const numberAbs = Math.abs(n);
    const signToDisplay = options.disableSign ? '' : signSymbol;
    const valueToDisplay = Intl.NumberFormat('en-US', {
        notation: options.compactNotation ? 'compact' : 'standard',
        style: 'decimal',
        signDisplay: 'never',
        minimumFractionDigits: maximumFractionDigits === 0 ? 0 : 2,
        maximumFractionDigits,
    })
        .format(numberAbs)
        .toLocaleLowerCase();
    return `${signToDisplay}${valueToDisplay}`;
};
const formatUSD = (n, options = {
    maximumFractionDigits: 2,
}) => {
    const currencyToDisplay = options.disableCurrencyDisplay ? '' : '$ ';
    const valueToDisplay = formatNumber(n, options);
    return `${currencyToDisplay}${valueToDisplay}`;
};
const formatUSDT = (n, options = {
    maximumFractionDigits: 2,
}) => {
    const currencyToDisplay = options.disableCurrencyDisplay ? '' : ' USDT';
    const valueToDisplay = formatNumber(n, options);
    return `${valueToDisplay}${currencyToDisplay}`;
};
const formatPercent = (n, options = {
    enablePlusSign: true,
    maximumFractionDigits: 2,
}) => {
    const valueToDisplay = formatNumber(n, Object.assign(Object.assign({}, options), { 
        // displyaing plus sign by default, to disable plus sign,
        // user should explicitly specify that in options via 'enablePlusSign: false'
        enablePlusSign: IsDefined(options.enablePlusSign) ? options.enablePlusSign : true }));
    return `${valueToDisplay}%`;
};
const getIncomeColor = (n, theme) => {
    if (n >= 0) {
        return theme.palette.success.main;
    }
    return theme.palette.error.main;
};
export { numberToFixed2, formatNumber, formatPercent, formatUSD, formatUSDT, getIncomeColor, };
